@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css');
body{
  font-family: 'Source Sans 3', sans-serif;
  font-size: large;
  background:#1F2837
}

.mega-menu {
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  height:0;
  overflow: hidden;
}

.mega-menu.hidden{
  display:block!important;
  transition-delay: 0ms;
}

.mega-menu.block{
  transition-delay: 0.2s;
  height: 300px;
  max-height: none;
  opacity: 1;
}

.navActive{
  background-repeat: repeat;
  background-size: 100% 200%;
  transition-delay: 0;
  transition: all .4s ease-in-out;
  /* background: linear-gradient(to bottom, transparent 50%, #1F2A37 0);
  background-repeat: repeat;
  background-size: 100% 200%;
  transition-delay: 0.1;
  transition: all .1s ease-in; */
}

.animasyon:hover{

}

.navActive.active{
  background:#1F2A37;
  background-position: 0 -100%;
}

@media only screen and (max-width: 600px) {
  .mega-menu.block{
    position: relative!important;
    inset: inherit !important;
    transform: none !important;
    transition:none !important;
    height:auto;
    opacity: 1;
  }
}

.mega-menu-grid:hover a{
  color: gray;
  transition: all .1s ease-in;

}
.mega-menu-grid div:hover a{
  color:white;
  transition: all .1s ease-in;
}

._lines:before,._lines:after, ._line:before,
._line:after {
    content: '';
    position: absolute;
    left: 42%;
    top: 0;
    bottom: 0;
    background: #343434;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    width: 1px;
    z-index: -1;
}
._lines:after {
    left: 58%;
}
._line:before {
    left: 20%;
    transform: unset;
}
._line:after {
    left: 80%;
    transform: unset;
}
._lines._lAdj:after {
    display: none;
}
._lines._lAdj ._line:before {
    left: 25%;
}
._lines._lAdj ._line:after {
    left: 75%;
}
._lines._lAdj:before {
    left: 50%;
}


.bounce {
  animation: slide1 1.3s ease-in-out infinite;
}
@keyframes slide1 {
  0%,
  100% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(5px);
  }
}


.sektorler-title::before{
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: 0;
  height: 2px;
  width: 80px;
  background-color: #e5e7eb;
}


.anname{
  -webkit-animation: fadeIn .5s both ease-in;
  animation: fadeIn .5s both ease-in;
  animation-name:fadeIn;
}


@-webkit-keyframes fadeIn {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0,-100%,0);
    transform: translate3d(0,-100%,0)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0,-100%,0);
    transform: translate3d(0,-100%,0)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0,-2000px,0);
    transform: translate3d(0,-2000px,0)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0,-2000px,0);
    transform: translate3d(0,-2000px,0)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig
}

.breadcrumb li:first-child::before{
  content: '';
  font-family:none;
}
.breadcrumb li::before{
  font-family: "Font Awesome 6 Free";
  content: "\f054";
  font-weight: 900;
  font-size:16px;
  color:#666;
  padding-right:5px;
}
